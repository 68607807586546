<template>
  <a-card>
    <a-row>
      <h3 style="display: inline-block">
        <HighlightContentWithHref
          v-if="highlightedTitle && attachedResources.length"
          :contentHtml="highlightedTitle"
          :href="documentLinkInOpsLevel(attachedResources[0].htmlUrl)"
          :isContentMarkdown="true"
        />
        <span v-else-if="attachedResources.length">
          <a :href="documentLinkInOpsLevel(attachedResources[0].htmlUrl)">{{
            title
          }}</a>
        </span>
        <span v-else>
          {{ title }}
        </span>
      </h3>
    </a-row>
    <a-row class="card-row doc-links">
      <span
        v-if="attachedResources.length"
        style="font-size: 12px; color: #8c8c8c"
      >
        Appears in
        <span v-if="hasFeatureFlag('docs_hub') && globallyPinned">
          <a :href="routes.documentation_path({ documentId })">Docs Hub,</a>
        </span>
        <span v-for="(resource, index) in attachedResources" :key="index">
          <span v-if="index < attachedResources.length">
            <a :href="documentLinkInOpsLevel(resource.htmlUrl)">{{
              resource.name
            }}</a
            ><template v-if="addComma(index)">, </template>
          </span>
        </span>
        <span v-if="notDisplayedResourcesCount > 0">
          <a-spin v-if="isLoadingResources" />
          <a v-else class="fetch-more-link" @click="fetchMoreAttachedResources">
            and {{ notDisplayedResourcesCount }} more.
          </a>
        </span>
      </span>
    </a-row>
    <a-row class="card-row">
      <HighlightMarkdownContent
        v-if="highlightedContentFragments.length"
        :fragments="highlightedContentFragments"
      />
      <span v-else class="limited-content">
        {{ content }}
      </span>
    </a-row>
    <a-row v-if="showLastEditedDate" class="card-row">
      <a-icon style="font-size: 12px; color: #8c8c8c" type="clock-circle" />
      <span style="font-size: 12px; color: #8c8c8c"
        >Last Updated {{ lastEditedDate }}</span
      >
      <span style="margin: 4px; color: #d9d9d9">|</span>
      <span v-if="showDocumentPath" class="doc-link">
        <OpsIcon color="grey" :type="repoType" />
        <HighlightDocumentContent
          v-if="highlightedDocumentPath"
          :contentHtml="fullHighlightedPath"
        />
        <span v-else> {{ repoDisplayName }}/{{ documentPath }} </span>
        <a :href="documentUrl" target="_blank">
          <a-icon style="font-size: 14px" type="export" />
        </a>
      </span>
    </a-row>
  </a-card>
</template>

<script>
import HighlightContentWithHref from "@/components/atoms/HighlightContentWithHref.vue";
import HighlightMarkdownContent from "@/components/atoms/HighlightMarkdownContent.vue";
import HighlightDocumentContent from "@/components/atoms/HighlightDocumentContent.vue";
import OpsIcon from "@/components/atoms/OpsIcon.vue";

import { featureFlags } from "@/mixins/featureFlags.js";
import { isEmpty } from "lodash";
import { humanizedDate } from "@/shared/helpers.js";

export default {
  components: {
    HighlightContentWithHref,
    HighlightMarkdownContent,
    HighlightDocumentContent,
    OpsIcon,
  },

  mixins: [featureFlags],
  inject: ["routes"],

  props: {
    documentId: {
      required: true,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    highlightedTitle: {
      required: false,
      type: String,
      default: undefined,
    },
    documentUrl: {
      required: false,
      type: String,
      default: undefined,
    },
    repoDisplayName: {
      required: false,
      type: String,
      default: undefined,
    },
    repoType: {
      required: false,
      type: String,
      default: "Git",
    },
    documentPath: {
      required: false,
      type: String,
      default: undefined,
    },
    highlightedDocumentPath: {
      required: false,
      type: String,
      default: undefined,
    },
    content: {
      required: false,
      type: String,
      default: "",
    },
    highlightedContentFragments: {
      required: false,
      type: Array,
      default: () => [],
    },
    editedAt: {
      type: String,
      required: false,
      default: undefined,
    },
    attachedResources: {
      required: false,
      type: Array,
      default: () => [],
    },
    attachedResourcesCount: {
      required: false,
      type: Number,
      default: 0,
    },
    isLoadingResources: {
      required: false,
      type: Boolean,
      default: false,
    },
    globallyPinned: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  computed: {
    showDocumentPath() {
      return (
        !isEmpty(this.repoDisplayName) &&
        !isEmpty(this.documentPath) &&
        !isEmpty(this.documentUrl)
      );
    },
    showLastEditedDate() {
      return !isEmpty(this.lastEditedDate);
    },
    lastEditedDate() {
      let date = this.editedAt;

      if (date) {
        date = humanizedDate(date);
      }

      return date;
    },
    notDisplayedResourcesCount() {
      if (!this.attachedResourcesCount) {
        return 0;
      }

      return this.attachedResourcesCount - this.attachedResources.length;
    },
    fullHighlightedPath() {
      if (
        isEmpty(this.highlightedDocumentPath) ||
        isEmpty(this.repoDisplayName)
      ) {
        return "";
      } else {
        return `${this.repoDisplayName}/${this.highlightedDocumentPath}`;
      }
    },
  },

  methods: {
    documentLinkInOpsLevel(resourceHtmlUrl) {
      return `${resourceHtmlUrl}/tech-docs?documentId=${this.documentId}`;
    },
    addComma(index) {
      return (
        index < this.attachedResources.length - 1 ||
        this.attachedResourcesCount > this.attachedResources.length
      );
    },
    fetchMoreAttachedResources() {
      this.$emit("fetchMoreAttachedResources", this.documentId);
    },
  },
};
</script>

<style scoped>
.doc-link {
  font-size: 0.85em;
  font-weight: 400;
  margin-left: 2px;
  color: #8c8c8c;
}

.card-row {
  padding-bottom: 6px;
}

.limited-content {
  max-height: 60px;
  display: inline-block;
  overflow: hidden;
}
</style>
