<template>
  <a-list
    item-layout="vertical"
    size="large"
    :pagination="listPagination"
    :data-source="documentList"
  >
    <a-list-item v-for="(document, index) in documentList" :key="index">
      <a-skeleton v-if="loading" />
      <DocumentSearchResultCard
        v-else
        :documentId="document.id"
        :title="document.title"
        :highlightedTitle="highlightedProperty(document, 'title')"
        :documentUrl="document.htmlUrl"
        :repoDisplayName="documentSourceDisplayName(document)"
        :repoType="documentSourceType(document)"
        :documentPath="document.pathInRepository"
        :isLoadingResources="document.isLoadingResources"
        :highlightedDocumentPath="
          highlightedProperty(document, 'path_in_repository')
        "
        :editedAt="documentEditedAt(document)"
        :content="document.content"
        :highlightedContentFragments="highlightedContentFragments(document)"
        :attachedResourcesCount="document.attachedResources.totalCount"
        :attachedResources="document.attachedResources.nodes"
        :globallyPinned="document.globallyPinned"
        @fetchMoreAttachedResources="fetchMoreAttachedResources"
      />
    </a-list-item>
  </a-list>
</template>

<script>
import DocumentSearchResultCard from "./DocumentSearchResultCard.vue";

export default {
  components: {
    DocumentSearchResultCard,
  },

  props: {
    documents: {
      required: true,
      type: Array,
    },
    itemsPerPage: {
      type: Number,
      required: false,
      default: 5,
    },
    totalCount: {
      type: Number,
      required: false,
      default: 0,
    },
    loading: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  computed: {
    listPagination() {
      return this.totalCount > this.itemsPerPage ? this.pagination : false;
    },
    documentList() {
      if (this.loading) {
        return Array.from({ length: this.itemsPerPage }, () => {});
      } else {
        return this.documents;
      }
    },
    pagination() {
      return {
        onChange: (page) => {
          this.$emit("pageChange", { page });
        },
        pageSize: this.itemsPerPage,
        total: this.totalCount,
      };
    },
  },

  methods: {
    documentEditedAt(document) {
      return document.metadata?.last_edited_date;
    },
    documentSourceType(document) {
      return document.source?.type;
    },
    documentSourceDisplayName(document) {
      return document.source?.displayName;
    },
    highlightedProperty(document, property) {
      if (!document.highlights || !document.highlights[property]) {
        return null;
      } else {
        return document.highlights[property][0];
      }
    },
    highlightedContentFragments(document) {
      if (!document.highlights) {
        return null;
      } else {
        return document.highlights["content"];
      }
    },
    fetchMoreAttachedResources(documentId) {
      this.$emit("fetchMoreAttachedResources", documentId);
    },
  },
};
</script>
