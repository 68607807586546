<template>
  <div>
    <DismissibleBanner
      v-if="isSpoofing"
      type="error"
      :square="true"
      :showIcon="false"
      :expiryReference="spoofBannerExpiryReference"
      :ttl="spoofBannerTTL"
      :useSessionStorage="true"
      messageOnClose="Spoofing banner on this account will be closed for 15 minutes."
      style="
        text-align: center;
        position: sticky;
        z-index: 999;
        width: 100%;
        top: 0;
      "
    >
      <template slot="description">
        <b>Warning!</b> You are spoofing a real user on account
        <b>{{ currentUser.user.account.name }}</b
        >. Please be careful!
        <a-button style="margin-left: 10px" @click="onSpoofCancelClicked">
          Stop Spoofing
        </a-button>
      </template>
    </DismissibleBanner>
    <a-layout id="main-layout" class="top-layout">
      <a-layout id="main-content">
        <a-layout-content class="global-content">
          <slot name="content">
            <div></div>
          </slot>
        </a-layout-content>
        <div class="expando" />
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import DismissibleBanner from "@/components/molecules/DismissibleBanner.vue";
import { internalNotifications } from "@/mixins/internalNotifications.js";
import { errors } from "@/mixins/errors.js";

import store from "@/store/index.js";
import application from "@/modules/application/index.js";
import members from "@/modules/teams/members/index.js";
import teams from "@/modules/teams/index.js";
import { mapActions } from "vuex";

const SpoofBannerTTL = 900; // 15 minutes in seconds

import * as Routes from "@/routes.js";
import mermaid from "mermaid";

export default {
  components: {
    DismissibleBanner,
  },

  mixins: [internalNotifications, errors],

  provide() {
    return {
      currentUser: this.currentUser,
      featureFlags: this.featureFlags,
      systemToggles: this.systemToggles,
      routes: this.routes,
    };
  },

  props: {
    currentUser: {
      type: Object,
      required: true,
    },
    urls: {
      type: Object,
      default: () => {},
    },
    featureFlags: {
      type: Array,
      default: () => [],
    },
    systemToggles: {
      type: Array,
      default: () => [],
    },
    isSpoofing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  store,

  data() {
    return {
      spoofBannerTTL: SpoofBannerTTL,
      // we set this on data so that the modal can be dismissed
      activeMenus: this.selectedMenuItems,
    };
  },

  computed: {
    spoofBannerExpiryReference() {
      return this.currentUser.user.account.uuid + "_spoofBannerExpiryReference";
    },
    routes() {
      return Routes;
    },
  },

  mounted() {
    mermaid.initialize();
  },

  created() {
    this.$store.registerModuleOnce("application", application);
    this.$store.registerModuleOnce("members", members);
    this.$store.registerModuleOnce("joinTeams", teams);
    this.fetchApplicationConfigs();
  },

  methods: {
    ...mapActions({
      fetchApplicationConfigs: "application/fetchApplicationConfigs",
    }),
    onSpoofCancelClicked() {
      if (this.urls["stop_spoof"]) {
        window.location.assign(this.urls["stop_spoof"]);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.sidebar-cta-button img {
  max-height: 24px;
  border-radius: 50%;
  border: 1px solid #e6f7ff;
}
#main-layout,
#main-content {
  background-color: white;
}
</style>
